.container {
  @apply flex flex-row gap-6 md:min-h-screen justify-between w-full max-md:flex-wrap;
}

.col {
  @apply flex flex-col gap-4 w-full;
}
.col1 {
  @apply flex flex-col gap-4 w-fit;
}
.title {
  @apply h-[30px] text-[20px] font-[700] text-[#3733BF] font-[Poppins];
}

.button {
  @apply h-[30px] w-[150px]  border-[0.5px] border-[#b6b4ff] text-[18px] 
  font-[700] text-[#3733BF] bg-[#eef8ff] rounded-[3px] font-[Poppins];
}

/* for dropdown */

.dropdown {
  @apply w-full p-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
    text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] h-[30px] relative flex flex-row;
}

.selectedoption {
  @apply w-full;
}

.optionarea {
  @apply origin-top-right absolute right-0 mt-6 w-fit  rounded-[3px]  shadow-lg bg-[#FFE5BF] z-50;
}

.option {
  @apply text-[#3733BF] text-[13px]
    block  p-2  w-full  rounded-[3px] 
     hover:bg-[#3733BF] hover:text-white;
}
