.container {
  @apply flex flex-row gap-6;
}

/*FOR TITLE 1 STYLE*/

.title {
  @apply font-[700] text-[20px] text-[#3733bf] font-[Poppins];
}

/*FOR SEARCH INPUT 1 STYLE*/

.search {
  @apply flex flex-row w-[500px];
}
.searchicon {
  @apply p-[11px] shrink-0 bg-[#F99E1D] rounded-l-[10px];
}
.searchinput {
  @apply placeholder:text-[#F99E1D] pl-2 flex w-full text-[#F99E1D]  text-[20px] font-medium font-[Poppins]
    bg-[#ffe5bf] rounded-r-[10px];
}

/*FOR TABLE 1 STYLE*/

.table {
  @apply flex flex-col gap-2 w-[500px];
}
.titles {
  @apply flex flex-row h-[45px] items-center bg-[#f1f2f7]
    font-[Poppins] font-medium text-[18px] text-[#3733bf]  divide-x-2 divide-[#F99E1D] py-1;
}
.title1 {
  @apply basis-[15%] px-4 h-full items-center flex;
}
.title2 {
  @apply basis-[28%] px-4 h-full items-center flex;
}
.title3 {
  @apply basis-[35%] px-4 h-full items-center flex;
}
.title4 {
  @apply basis-[22%] px-4 h-full items-center flex;
}
.datas {
  @apply flex flex-row h-[45px] items-center 
    font-[Poppins] font-medium text-[18px] text-[#3733bf]
    divide-x-2 divide-[#F99E1D]  bg-[#F1F2F7] py-1 shrink-0;
}
.datas2 {
  @apply flex flex-row h-[45px] items-center 
    font-[Poppins] font-medium text-[18px] text-[#3733bf]
    divide-x-2 divide-[#F99E1D]  bg-[#FFE5BF] py-1 shrink-0;
}
.sdatas {
  @apply flex flex-row h-[45px] items-center 
    font-[Poppins] font-medium text-[18px] text-white
    divide-x-2 divide-[#F99E1D]  bg-[#3733BF] py-1 shrink-0;
}
.data1 {
  @apply basis-[15%] px-4 h-full items-center flex;
}
.data2 {
  @apply basis-[28%] px-4 h-full items-center flex;
}
.data3 {
  @apply basis-[35%] px-4 h-full items-center flex;
}
.data4 {
  @apply basis-[22%] px-4 h-full items-center flex;
}

/*FOR SEARCH INPUT 2 STYLE*/

.search1 {
  @apply flex flex-row w-[550px];
}

/*FOR TABLE 2 STYLE*/

.table1 {
  @apply flex flex-col gap-2 w-[550px];
}

.title5 {
  @apply basis-[70%] px-4 h-full items-center flex;
}
.title6 {
  @apply basis-[15%] px-4 h-full items-center flex;
}
.title7 {
  @apply basis-[15%] px-4 h-full items-center flex;
}

.data5 {
  @apply basis-[70%] px-4 h-full items-center flex;
}
.data6 {
  @apply basis-[15%] px-4 h-full items-center flex;
}
.data7 {
  @apply basis-[15%] px-4 h-full items-center flex;
}

/*FOR SEARCH INPUT 3 STYLE*/

.search2 {
  @apply flex flex-row w-[700px];
}

/*FOR TABLE 3 STYLE*/

.table2 {
  @apply flex flex-col gap-2 w-[700px];
}
.title8 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.title9 {
  @apply basis-[50%] px-4 h-full items-center flex;
}
.title10 {
  @apply basis-[20%] px-4 h-full items-center flex;
}
.title11 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.title12 {
  @apply basis-[10%] px-4 h-full items-center flex;
}

.data8 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.data9 {
  @apply basis-[50%] px-4 h-full items-center flex;
}
.data10 {
  @apply basis-[20%] px-4 h-full items-center flex;
}
.data11 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.data12 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
