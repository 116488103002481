.container {
    @apply flex flex-col gap-3 w-[1055px] h-[418px]  ml-auto overflow-y-auto overflow-x-hidden;
}

.titles {
    background: #F1F2F7;
    @apply flex flex-row min-h-[45px] items-center font-[Poppins] font-medium text-[18px] text-[#3733bf] divide-x-2 divide-[#F99E1D] py-1;
}

.title1 {
    @apply basis-[5%] px-4 h-full items-center flex;
}

.title2 {
    @apply basis-[27%] px-4 h-full items-center flex;
}

.title3 {
    @apply basis-[17%] px-4 h-full items-center flex;
}

.title4 {
    @apply basis-[21%] px-4 h-full items-center flex;
}

.title5 {
    @apply basis-[20%] px-4 h-full items-center flex;
}

.title6 {
    @apply basis-[7%] px-4 h-full items-center flex;
}

.datas {
    @apply flex flex-row min-h-[45px] items-center font-[Poppins] font-medium text-[18px] text-[#3733bf] divide-x-2 divide-[#F99E1D] bg-[#F1F2F7] py-1;
}

.data1 {
    @apply basis-[5%] px-4 h-full items-center flex;
}

.data2 {
    @apply basis-[27%] px-4 h-full items-center flex;
}

.data3 {
    @apply basis-[17%] px-4 h-full items-center flex;
}

.data4 {
    @apply basis-[21%] px-4 h-full items-center flex;
}

.data5 {
    @apply basis-[20%] px-4 h-full items-center flex;
}

.data6 {
    @apply basis-[7%] w-[20px] px-4 h-full items-center flex;
}

.even {
    @apply bg-[#F1F2F7]; 
}
.odd {
    @apply bg-[#FFE5BF]; 
}