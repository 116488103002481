.page {
  @apply bg-[url("../assets/background.svg")]  bg-cover min-h-screen w-full flex justify-center items-center pt-[150px];
}
.container {
  @apply flex w-full gap-4 flex-wrap items-center justify-center  py-10 px-[2.5%];
}
.box {
  @apply flex flex-col md:w-[320px] md:h-[280px] items-center justify-center md:gap-8  bg-[#ffffffc9] border-[4px] border-[#339ce9] shadow-md w-[160px] h-[140px];
}
.img {
  @apply object-contain h-1/2;
}
.title {
  @apply flex  text-[#3733bf] md:text-[28px] text-[14px] font-[Montserrat] font-medium text-center leading-[34px];
}
.newComponent {
  @apply flex w-full gap-4 flex-wrap items-center justify-end py-10 px-[8.5%];
}

.customContainer {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alert {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid #f5c6cb;
}

.message{
margin-bottom: 20px;
text-align: center;
}


