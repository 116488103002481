.modal {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-[35%]  flex  items-center justify-center z-50;
}

.modalbox {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @apply bg-white divide-y-[1px] divide-[#3733BF] sm:px-[30px] px-[10px] flex flex-col  justify-between md:w-[1707px] md:h-[770px] h-fit w-full rounded-[23px] ;
}

.uppertabs {
  @apply flex flex-row border-b-[0.3px] border-[#3733BF] justify-between  items-center xss:py-8 py-6 flex-wrap-reverse gap-2;
}
.tableftside {
  @apply flex flex-row;
}
.tabrightside {
  @apply flex flex-row gap-5 flex-wrap-reverse ;
}
.tabtext1 {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#FFE5BF] rounded-r-[6px] text-[#f99e1d] 
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.tab {
  @apply flex flex-row h-[30px];
}
.tabicon_left {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#F99E1D];
}
.tabtext_left {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#FFE5BF] rounded-r-[6px] text-[#F99E1D]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}

.tabicon_right {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733BF];
}
.tabtext_right {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#AFDDFF] rounded-r-[6px] text-[#3733BF]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}

.contents{
  @apply border-none h-[100%] py-5
}

.lowertabs {
  @apply flex flex-row justify-between items-center xss:py-8 py-6 flex-wrap-reverse gap-2;
}


.buttoncontainer {
  @apply flex items-center justify-start w-[64px] h-[32px]  pl-2 rounded-[14px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.inputbutton {
  @apply w-[28px] h-[28px] bg-[#B6B4FF] rounded-[50%] cursor-pointer transition-all translate-x-0
}
.activeinputbutton {
  @apply w-[28px] h-[28px] bg-[#3733BF] rounded-[50%] cursor-pointer translate-x-5 transition-all
}


.dropdown {
  @apply w-[180px] p-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] h-[30px] relative flex flex-row;
}

.selectedoption {
  @apply w-full;
}

.optionarea {
  @apply origin-top-right absolute right-0 mt-6 w-fit  rounded-[3px]  shadow-lg bg-[#FFE5BF] z-50;
}

.option {
  @apply text-[#3733BF] text-[13px]
  block  p-2  w-full  rounded-[3px] 
   hover:bg-[#3733BF] hover:text-white;
}
.directive {
  @apply text-[#3733BF] mb-2 p-2
}
.odd {
  @apply bg-[#FFE5BF] 
}
.even {
  @apply bg-[#F1F2F7] 
}