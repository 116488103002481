.container {
  @apply flex flex-col gap-3;
}
.lessonshoursinterval {
  @apply flex flex-row w-full items-center gap-5;
}
.text {
  @apply font-[Poppins] font-[500] text-[13px] leading-[100%] text-[#3733bf];
}
.input2 {
  @apply w-[150px] pl-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] h-[30px];
}
.copyarea {
  @apply flex flex-row gap-5 items-center w-full;
}
.copybutton {
  @apply px-4 py-3
  bg-[#f99e1d] rounded-[3px] text-[#ffffff] font-[Poppins] font-[700] text-[13px] leading-[100%];
}

.lessonhoursarea {
  @apply w-full flex flex-row gap-8;
}
.hoursinputs {
  @apply flex flex-col gap-1;
}
.hourstitles {
  @apply flex flex-row  gap-6  justify-end items-center
  font-[Poppins] font-[500] text-[13px] leading-[100%] text-[#3733bf];
}
.hourstitle {
  @apply w-[80px] text-center;
}
.hoursinput {
  @apply flex flex-row items-center gap-6;
}
.hoursinputtitle {
  @apply w-[40px] font-[Poppins] font-[500] text-[13px] leading-[100%] text-[#3733bf];
}
.hoursinputtext {
  @apply w-[80px] pl-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] h-[30px];
}

.hoursdescbox {
  @apply w-full h-fit p-3 gap-3 flex flex-col mt-4
  bg-[#ffe5bf] border-[0.5px] border-[#f99e1d] rounded-[3px];
}
.hoursdesc {
  @apply font-[Poppins] font-[500] text-[13px] leading-[100%] text-[#3733bf];
}
.checkbox {
  @apply flex items-center gap-4;
}
.hoursdesc1 {
  @apply font-[Poppins] font-[700] text-[13px] leading-[100%] text-[#3733bf];
}
.hoursdesctick {
  @apply w-6 h-6  bg-none  rounded-full appearance-none border-2 border-[#f99e1d]
  checked:bg-[#f99e1d] checked:border-2 checked:border-[#ffe5bf] checked:ring-2 checked:ring-[#f99e1d] checked:ring-opacity-50 shrink-0 cursor-pointer;
}
