.container {
  @apply flex flex-col gap-3 min-w-[1200px] min-h-[500px];
}
.titles {
  background: #ffe5bf;
  @apply flex flex-row h-[45px] items-center 
  font-[Poppins] font-medium text-[18px] text-[#3733bf]  divide-x-2 divide-[#F99E1D] py-1;
}
.title1 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.title2 {
  @apply basis-[11%] px-4 h-full items-center flex;
}
.title3 {
  @apply basis-[14%] px-4 h-full items-center flex;
}
.title4 {
  @apply basis-[26%] px-4 h-full items-center flex;
}
.title5 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.title6 {
  @apply basis-[7%] px-4 h-full items-center flex;
}

.title7 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.title8 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.title9 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.title10 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.title11 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.title12 {
  @apply basis-[7%] px-4 h-full items-center flex;
}

.datas {
  @apply flex flex-row h-[45px] items-center 
  font-[Poppins] font-medium text-[18px] text-[#3733bf]
  divide-x-2 divide-[#F99E1D]  bg-[#F1F2F7] py-1;
}
.data1 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.data2 {
  @apply basis-[11%] px-4 h-full items-center flex;
}
.data3 {
  @apply basis-[14%] px-4 h-full items-center flex;
}
.data4 {
  @apply basis-[26%] px-4 h-full items-center flex;
}
.data5 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.data6 {
  @apply basis-[7%]  px-4 h-full items-center flex;
}
.data7 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.data8 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.data9 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.data10 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.data11 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.data12 {
  @apply basis-[7%] px-4 h-full items-center flex;
}

/* upperside */

.upperside {
  @apply flex flex-row justify-between w-full mt-2;
}
.leftside {
  @apply flex flex-row  gap-4 w-full;
}

.tab {
  @apply flex flex-row h-[30px];
}
.tabicon {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}

.dates {
  @apply flex flex-row justify-between px-2 
  w-[200px] h-[32px] bg-[#afddff]
   border-[0.5px] border-[#3733bf] rounded-[3px];
}
.date {
  @apply flex flex-row items-center justify-between w-full mx-4
  font-[Poppins] text-[#3733bf] font-[500] text-[13px] leading-[100%];
}

/*for dropdown*/

.dropdown {
  @apply w-[135px] p-2 
  text-[#3733bf] font-[Poppins] font-[500] text-[13px] leading-[100%]  relative flex flex-row
  h-[32px] bg-[#afddff]
  border-[0.5px] border-[#3733bf] rounded-[3px];
}

.selectedoption {
  @apply w-full;
}

.optionarea {
  @apply origin-top-right absolute right-0 mt-6 w-fit  rounded-[3px]  shadow-lg bg-[#FFE5BF] z-50;
}

.option {
  @apply text-[#3733BF] text-[13px]
  block  p-2  w-full  rounded-[3px] 
   hover:bg-[#3733BF] hover:text-white;
}

.hoursdescbox {
  @apply w-full h-fit p-3 gap-3 flex flex-col mt-4
  bg-[#ffe5bf] border-[0.5px] border-[#f99e1d] rounded-[3px];
}
.hoursdesc {
  @apply font-[Poppins] font-[500] text-[13px] leading-[100%] text-[#3733bf];
}
.dropcheck {
  @apply divide-y-[1px] divide-[#F99E1D] bg-[#ffe5bf] border-[0.5px] border-[#f99e1d] max-h-[260px] overflow-y-auto;
}
.checkbox {
  @apply flex items-center justify-between gap-4 px-3 
  w-full h-[32px];
}
.hoursdesc1 {
  @apply text-[#3733BF] text-[13px] leading-[100%];
}
.hoursdesctick {
  @apply w-5 h-5 bg-none  rounded-full appearance-none border-2 border-[#3733bf]
  checked:bg-[#3733bf] checked:border-2 checked:border-[#ffe5bf] checked:ring-2 checked:ring-[#3733bf] checked:ring-opacity-90 shrink-0 cursor-pointer;
}