.modal {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-[35%]  flex  items-center justify-center z-50;
}
.modalbox {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @apply bg-white divide-y-[1px] divide-[#3733BF] sm:px-[30px] px-[10px] flex flex-col  justify-between md:w-[770px] md:h-[690px] h-fit w-full rounded-[23px];
}
.uppertabs {
  @apply flex flex-row justify-between  items-center  xss:py-8 py-6  flex-wrap-reverse gap-2;
}
.tableftside {
  @apply flex flex-row;
}
.tabrightside {
  @apply flex flex-row gap-5 flex-wrap-reverse gap-2;
}
.tab1 {
  @apply flex flex-row h-[30px];
}
.tabicon1 {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px]  bg-[#F99E1D];
}
.tabtext1 {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#FFE5BF] rounded-r-[6px] text-[#f99e1d]  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.tab {
  @apply flex flex-row h-[30px];
}
.tabicon {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.inputs {
  @apply flex flex-col gap-5 justify-center h-full   xss:py-8 py-6 ;
}
.input {
  @apply h-[30px] flex flex-row gap-4;
}
.inputtitle {
  @apply flex  w-[150px] items-center text-[#3733bf] font-[Poppins] font-[500] text-[13px] leading-[100%];
}
.inputtext {
  @apply w-full pl-2 text-[#3733bf] font-[Poppins] font-[500] text-[13px] leading-[100%]
  bg-[#eef8ff] border-[0.5px] border-[#b6b4ff] rounded-[3px];
}
.lowertabs {
  @apply flex flex-row justify-between items-center  xss:py-8 py-6  flex-wrap-reverse gap-2;
}
