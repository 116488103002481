.container {
  @apply w-full  justify-between  flex flex-row  gap-6 py-6;
}
.leftside {
  @apply flex flex-row items-center gap-4;
}
.logo {
}
.okulumlogo {
}
.rightside {
  @apply flex flex-row items-center gap-5 max-sm:gap-1;
}
.home {
  @apply object-contain;
}
.logoutarea {
  @apply flex flex-row gap-1;
}
.logout {
  @apply p-[11px] bg-[#3733bf] rounded-[3px] font-bold text-[20px] text-white font-[Poppins] leading-[100%] max-sm:hidden;
}
