.container {
  @apply fixed w-full h-[150px] bg-[#3733BF] justify-between top-0 flex flex-row md:px-12 px-5 py-[40px] sm:gap-6 gap-3;
}
.leftside {
  @apply flex flex-row items-center;
}
.logo {
  @apply h-[90px] w-[90px] object-contain;
}
.okulumlogo {
  @apply h-[150px] pt-5 max-xmd:hidden;
}
.rightside {
  @apply flex flex-row items-end justify-end md:gap-12 gap-6 flex-wrap;
}
.social {
  @apply flex flex-row h-[25px] md:gap-4 gap-2;
}
.sociallogo {
}
.notification {
  @apply object-contain max-sm:h-10 ;
}
.notificationarea {
  @apply flex flex-col gap-1 place-self-start overflow-auto
  w-full;
}
.notificationtext{@apply text-start whitespace-normal 
 
}

.logoutarea {
  @apply flex flex-row gap-1;
}
.logout {
  @apply font-bold text-[20px] text-white font-[Poppins] leading-[100%] max-xss:hidden  flex items-center;
}

.tab {
  @apply flex flex-row h-[30px] place-self-end;
}
.tabicon {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
