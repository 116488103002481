.container {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  
    @apply w-full  xl:justify-between justify-around flex flex-row  gap-6 bg-[#F1F2F7] items-center  h-full
     rounded-[12px] px-[20px] py-[15px] flex-wrap;
  }
  .exams {
    @apply flex flex-row gap-3 font-[Poppins] items-center text-[24px] font-bold text-[#3733bf];
  }
  .examstext {
    @apply cursor-default;
  }
  .examsvideo {
  }
  .search {
    @apply flex flex-row;
  }
  .searchicon {
    @apply p-[11px] shrink-0 bg-[#F99E1D] rounded-l-[10px];
  }
  .searchinput {
    @apply placeholder:text-[#F99E1D] pl-2 flex w-full text-[#F99E1D]  text-[20px] font-medium font-[Poppins]
    bg-[#ffe5bf] rounded-r-[10px];
  }
  .reload {
    @apply p-[5px] rounded-md bg-[#3733bf];
  }
  .tabs {
    @apply flex flex-row gap-6 flex-wrap;
  }
  .tab {
    @apply flex flex-row items-center;
  }
  .tabicon {
    @apply w-[40px] h-[40px] items-center justify-center flex rounded-l-[10px] bg-[#3733bf];
  }
  .tabtext {
    @apply py-2 px-4 h-[40px] flex items-center cursor-default font-[Poppins] text-[#3733bf] font-medium text-[20px]
    bg-[#afddff] rounded-r-[10px];
  }
  