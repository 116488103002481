.page {
  @apply bg-[#E7E6E6]  min-h-screen w-full flex justify-center px-[2.5%] pb-20;
}
.container {
  @apply flex flex-col w-full gap-4;
}
.header {
}
.bar {
}
.table { @apply overflow-x-auto
}
