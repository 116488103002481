.container {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

    @apply flex flex-col min-w-[1200px] font-[Poppins] text-[13px] text-[#3733BF] bg-white rounded-[12px] p-5;
}
.header {
    @apply flex min-w-[1200px];
}

.header_dayName {
    @apply min-w-[85px] h-[20px] border-r border-r-[#D9D9D9]
}

.header_cell {
    @apply flex justify-center items-center ml-auto w-[85px] h-[20px] border-b border-b-[#AFDDFF] border-r border-r-[#D9D9D9]
}

.body {
    @apply flex flex-row justify-center items-center min-w-[1200px];
}

.body_dayName {
    @apply flex flex-row items-center min-w-[85px] h-[50px] border-r border-r-[#AFDDFF]
}

.body_cell {
    @apply flex justify-center items-center w-[85px] h-[50px] bg-[#EEF8FF] border-r border-b border-r-[#AFDDFF] border-b-[#AFDDFF]
}