.page {
  @apply bg-[url("../assets/background.svg")]  bg-cover min-h-screen w-full flex justify-center items-center;
}
.container {
  @apply flex flex-col xss:w-[400px] w-full items-center  gap-4 max-xss:px-2 py-10;
}
.logo {
  @apply w-[200px];
}
.inputs {
  @apply flex flex-col gap-6  w-full;
}
.input {
  @apply px-8 h-[50px] bg-white rounded-[100px] font-[Montserrat] text-black font-bold text-[20px] leading-6;
}

.links {
  @apply flex flex-row gap-6 flex-wrap justify-center;
}

.link {
  @apply font-[Montserrat] font-bold text-white text-[20px] leading-6;
}

.button {
  @apply rounded-full py-4 px-10 xss:w-[300px] w-full mt-4 font-[Montserrat] font-bold text-white bg-[#f99e1d] text-[32px] leading-10;
}
