.container {
    @apply w-full p-12 h-[784px] rounded-[23px] bg-[#fff]
}

.container_item {
    @apply flex mb-4
}

.title {
    @apply w-[120px] text-[13px] text-[#3733BF] leading-[13px] font-[500]
}

.item_left {
    @apply w-[50%] flex justify-between
}

.pdf_btn,
.excel_btn {
    @apply flex items-center justify-center ms-auto w-[160px] h-[32px] rounded-[3px] bg-[#EEF8FF] border-[0.3px] border-[#B6B4FF] cursor-pointer text-[13px] text-[#3733BF] leading-[13px] font-[500];
}

.dropdown {
    @apply ms-60 p-2 rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff] text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] min-w-[414px] h-[32px] relative flex flex-row;
}

.dropdown_btn {
    @apply ms-auto px-1
}

.optionsarea {
    @apply origin-top-right absolute right-0 mt-6 w-[335px] h-fit rounded-[3px] shadow-lg bg-[#FFE5BF] z-50 border-t-[0.3px] border-r-[0.3px] border-l-[0.3px] border-[#F99E1D];
}

.option {
    @apply w-[335px] h-[31px] text-[#3733bf] text-start border-b-[0.3px] border-[#F99E1D] ps-2
}

.input {
    @apply ms-60 rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff] text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] min-w-[237px] h-[32px] relative flex flex-row;
}

.input input {
    @apply bg-transparent w-full ms-2 outline-none cursor-pointer
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
    cursor: pointer;
}


.checkbox_wrapper {
    @apply flex justify-start items-center w-[237px] h-[32px] ms-60
}
.checkbox_container {
    @apply flex justify-center items-center
}
.checkbox {
    @apply font-[20px] w-[20px] h-[20px] accent-[#3733bf]
}

.checkbox_wrapper span {
    @apply  ms-2 me-3 text-[#3733bf] font-[Poppins] font-[500] text-[15px]
}