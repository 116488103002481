.modal {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-[35%]  flex  items-center justify-center z-50;
}

.modalbox {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @apply bg-white divide-y-[1px] divide-[#3733BF] sm:px-[30px] px-[10px] flex flex-col  justify-between  max-w-[1500px] max-h-[800px] h-full w-full rounded-[23px];
}

.uppertabs {
  @apply flex flex-row justify-between  items-center xss:py-8 py-6 flex-wrap-reverse gap-2;
}
.tableftside {
  @apply flex flex-row;
}
.tabrightside {
  @apply flex flex-row gap-5 flex-wrap-reverse;
}
.tab1 {
  @apply flex flex-row h-[40px];
}
.tabicon1 {
  @apply w-[40px] items-center justify-center flex rounded-l-[6px]  bg-[#F99E1D];
}
.tabtext1 {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#FFE5BF] rounded-r-[6px] text-[#f99e1d] 
  font-[Poppins] font-[500] text-[15px] leading-[100%]
  w-[120px];
}
.tab {
  @apply flex flex-row h-[30px];
}
.tabicon {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.tab2 {
  @apply flex flex-row h-[40px];
}
.photoinput {
  @apply absolute z-10  w-[40px] h-[40px]  opacity-0;
}
.tabicon2 {
  @apply w-[40px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext2 {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  
  font-[Poppins] font-[500] text-[15px] leading-[100%]
  w-[120px];
}
.inputs {
  @apply flex flex-col gap-5 justify-start h-full  xss:py-8 py-6 overflow-auto w-full;
}
.upperinputs {
  @apply flex flex-row w-full gap-5   max-h-[150px] h-full xl:justify-between min-w-[1100px];
}
.messageArea {
  @apply bg-[#FFE5BF] text-[#F99E1D] border-[1px] border-[#F99E1D] text-[13px] font-[500]
  placeholder:text-[13px] placeholder:font-[500] placeholder:text-[#F99E1D] p-2 max-w-[950px] w-full;
}
.checkboxArea {
  @apply flex flex-col gap-3 flex-wrap items-start justify-center max-h-[130px] h-full 
  max-w-[100px] w-full;
}
.checkboxBox {
  @apply flex flex-row items-center gap-2;
}
.checkbox {
  background: #afddff;
  border: 0.5px solid #3733bf;
  width: 20px;
  height: 20px;

  @apply rounded-sm shrink-0;
}
.checkboxText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  color: #3733bf;
}
.buttonArea {
  @apply flex flex-col gap-2 w-1/3 justify-center items-center;
}
.lowerinputs {
  @apply flex flex-row gap-5 min-w-[1100px];
}

.lowertabs {
  @apply flex flex-row justify-between items-center xss:py-8 py-6 flex-wrap-reverse gap-2;
}

/*FOR TITLE 1 STYLE*/
.tablearea1 {
  @apply flex flex-col gap-2 w-[250px] overflow-y-auto max-h-[460px] h-full;
}
.tablearea2 {
  @apply flex flex-col gap-2 w-[400px] overflow-y-auto max-h-[460px] h-full;
}

.tabletitle {
  @apply font-[700] text-[20px] text-[#3733bf] font-[Poppins];
}

/*FOR SEARCH INPUT 1 STYLE*/

.search {
  @apply flex flex-row;
}
.searchicon {
  @apply p-[11px]  bg-[#F99E1D] rounded-l-[10px];
}
.searchinput {
  @apply placeholder:text-[#F99E1D] pl-2 flex w-full text-[#F99E1D]  text-[20px] font-medium font-[Poppins]
    bg-[#ffe5bf] rounded-r-[10px];
}

/*FOR TABLE 1 STYLE*/

.table {
  @apply flex flex-col gap-2;
}

.title1 {
  @apply flex flex-row h-[45px] items-center bg-[#f1f2f7]
  font-[Poppins] font-medium text-[18px] text-[#3733bf] 
   w-full px-4 divide-x-2 divide-[#F99E1D] mt-2 gap-4 py-1;
}

.titletext {
  @apply pl-2;
}

.datas {
  @apply flex flex-row h-[45px] items-center 
    font-[Poppins] font-medium text-[18px] text-[#3733bf]
    divide-x-2 divide-[#F99E1D] px-4 bg-[#F1F2F7] py-1  gap-4;
}
.datas2 {
  @apply flex flex-row h-[45px] items-center 
    font-[Poppins] font-medium text-[18px] text-[#3733bf]
    divide-x-2 divide-[#F99E1D]  bg-[#FFE5BF] py-1;
}

.data1 {
  @apply w-full px-4 h-full items-center flex;
}
