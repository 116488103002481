.modal {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-[35%]  flex  items-center justify-center z-50;
}

.modalbox {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @apply bg-white divide-y-[1px] divide-[#3733BF] sm:px-[30px] px-[10px] flex flex-col  justify-between md:w-[770px] 2xl:h-fit h-[650px] w-full  rounded-[23px];
}

.uppertabs {
  @apply flex flex-row justify-between  items-center xss:py-8 py-4 flex-wrap-reverse gap-2;
}
.tableftside {
  @apply flex flex-row;
}
.tabrightside {
  @apply flex flex-row gap-5 flex-wrap-reverse;
}
.tab1 {
  @apply flex flex-row h-[30px];
}
.tabicon1 {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px]  bg-[#F99E1D];
}
.tabtext1 {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#FFE5BF] rounded-r-[6px] text-[#f99e1d] 
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.tab {
  @apply flex flex-row h-[30px];
}
.tabicon {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.inputbox {
  @apply overflow-auto mb-4;
}
.inputarea {
  @apply flex flex-col gap-6 justify-start h-full  xss:pt-8 pt-4 min-w-[600px] min-h-[600px];
}
.inputs {
  @apply flex flex-row w-full gap-8;
}
.input {
  @apply flex flex-col gap-1 w-[150px];
}
.input1 {
  @apply flex flex-col gap-1 w-full;
}
.inputtitle {
  @apply flex   items-center
  text-[#3733bf]  
  font-[Poppins] font-[500] text-[13px] leading-[100%];
}
.inputtext {
  @apply w-full pl-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] h-[30px];
}

.option {
}

.title {
  @apply font-[Poppins] font-[700] text-[13px] leading-[100%] text-[#3733bf];
}

.days {
  @apply flex flex-row w-full justify-between;
}
.day {
  @apply cursor-pointer font-[Poppins] font-[700] text-[13px] leading-[100%] text-[#f99e1d];
}
.sday {
  @apply cursor-pointer font-[Poppins] font-[700] text-[13px] leading-[100%] text-[#3733bf];
}

.lowertabs {
  @apply flex flex-row justify-between items-center xss:py-8 py-4 flex-wrap-reverse gap-2;
}

/*for dropdown*/

.dropdown {
  @apply w-full p-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] h-[30px] relative flex flex-row;
}

.selectedoption {
  @apply w-full;
}

.optionarea {
  @apply origin-top-right absolute right-0 mt-6 w-full  rounded-[3px]  shadow-lg bg-[#FFE5BF];
}

.option {
  @apply text-[#3733BF] text-[13px]
  block  p-2  w-full  rounded-[3px] 
   hover:bg-[#3733BF] hover:text-white;
}
