.modal {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-[35%]  flex  items-center justify-center z-50;
}

.modalbox {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @apply bg-white divide-y-[1px] divide-[#3733BF] sm:px-[30px] px-[10px] flex flex-col  justify-between lg:w-[1200px] md:h-[900px] h-fit w-full rounded-[23px];
}


.uppertabs {
  @apply flex flex-row justify-between  items-center md:py-4 py-4  flex-wrap-reverse gap-2;
}

.tableftside {
  @apply flex flex-row;
}
.tabrightside {
  @apply flex flex-row gap-5 flex-wrap-reverse;
}
.tab1 {
  @apply flex flex-row h-[30px];
}
.tabicon1 {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px]  bg-[#F99E1D];
}
.tabtext1 {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#FFE5BF] rounded-r-[6px] text-[#f99e1d] 
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.tab {
  @apply flex flex-row h-[30px];
}
.tabicon {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.inputs {
  @apply flex flex-col gap-5 max-md:gap-4 justify-center h-full  md:py-6 py-6;
}
.upperinputs {
}
.input {
  @apply h-[30px] max-sm:w-full flex flex-row gap-4 ;
}
.input1 {
  @apply h-[30px]  max-sm:w-[235px]    flex flex-row gap-4 relative;
}
.icon {
  @apply absolute right-[8px] top-[5px];
}
.icon1 {
  @apply absolute right-[8px] top-[7px];
}
.inputtitle {
  @apply flex  w-[110px] items-center
  text-[#3733bf]  
  font-[Poppins] font-[500] text-[13px] leading-[100%];
}

.inputtitle2 {
  @apply flex justify-center;
  @apply flex  w-[110px] items-center
  text-[#3733bf]  
  font-[Poppins] font-[500] text-[14px] leading-[100%];
}

.inputtitle1 {
  @apply flex justify-center;
  @apply flex  w-[110px] items-center
  text-[#3733bf]  
  font-[Poppins] font-[700] text-[18px] leading-[100%];
}

.inputtext {
  @apply sm:w-[235px] w-full pl-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%];
}

.inputtext1 {
  @apply sm:w-[235px] w-full pl-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%]
  flex flex-row;
}

.addButton {
  @apply ml-8 w-[74px] h-[32px]  bg-[#3733bf] rounded-[3px]
   text-[#ffffff] font-[Poppins] font-[700] text-[16px] leading-[100%];
}
.deleteButton {
  @apply ml-2 w-[74px] h-[32px] bg-[#f99e1d] rounded-[3px] text-[#ffffff] font-[Poppins] font-[700] text-[16px] leading-[100%];
}
/* Mobil için stiller */
@media screen and (max-width: 767px) {
  .addButton,
  .deleteButton {

  }
}


.calendararea {
  @apply flex flex-col items-center justify-center; /* Tam ortaya hizalar */
  @apply w-full md:gap-9 gap-4 mt-16;
  @apply mx-auto; /* Elemanı yatayda merkeze alır */
}

/* Ekstra küçük ekranlar için stiller */
@media (max-width: 640px) {
  .calendararea {
    @apply gap-4; /* Küçük boşluklar */
  }
}

/* Küçük ekranlar için stiller */
@media (min-width: 640px) {
  .calendararea {
    @apply gap-9; /* Büyük boşluklar */
  }
}

/* Mobil için stiller */
@media screen and (max-width: 767px) {
  .inputtitle,
  .inputtitle2,
  .inputtitle1 {
    @apply text-[#3733bf];
  }

  .inputtext,
  .inputtext1 {
    @apply text-[#3733bf];
  }
}

.dates {
  @apply flex flex-row justify-between px-2 md:ml-28
  w-[280px] h-[32px] bg-[#ffe5bf]
   border-[0.5px] border-[#f99e1d] rounded-[3px];
}
.date {
  @apply flex flex-row items-center justify-center 
  font-[Poppins] text-[#3733bf] font-[500] text-[13px] leading-[100%];
}
.calendar {
  @apply w-full flex flex-row justify-between 
   text-[#3733bf] font-[500] text-[13px] leading-[100%] max-h-[200px] overflow-auto;
}

.calendartitles {
  @apply flex flex-col gap-4 ;
}
.calendardatetitle {
  @apply flex  items-center justify-center h-[30px];
}
.calendarunittitles {
  @apply flex flex-col   justify-around
  w-[115px] ;
}
.calendarunit {
  @apply flex items-center justify-center h-[50px] flex-col;
}
.calendartable {
  @apply flex flex-col gap-4;
}
.calendardates {
  @apply flex flex-row  h-[30px]  divide-x-[1px] divide-[#D9D9D9];
}
.calendardate {
  @apply text-center  flex items-center justify-center w-[114px];
}
.calendarboxes {
  @apply flex flex-col bg-[#EEF8FF]  divide-y-[1px]  divide-[#AFDDFF] 
  border-[1px] border-[#AFDDFF];
}

.calendarboxrow {
  @apply flex flex-row  bg-[#EEF8FF]  divide-x-[1px]   divide-[#AFDDFF];
}
.calendarbox {
  @apply w-[114px] h-[50px] flex items-center justify-center;
}
/* Yeni eklenen stiller */

.calendarbox--closed {
  background-color: #ff0000;
  color: #ffffff;
}

.screenReaderText {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.lowertabs {
  @apply flex flex-row justify-between items-center md:py-6 py-3 flex-wrap-reverse gap-2;
}

/*for dropdown*/

.dropdown {
  @apply sm:w-[235px] w-full p-2  rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff]
  text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] h-[30px]  relative flex flex-row;
  margin-right:auto;
}

.selectedoption {
  @apply w-full;
}

.optionarea {
  @apply origin-top-right absolute right-0 mt-6 w-fit  rounded-[3px]  shadow-lg bg-[#FFE5BF] z-50;
}

.option {
  @apply text-[#3733BF] text-[13px]
  block  p-2  w-full  rounded-[3px] 
   hover:bg-[#3733BF] hover:text-white;
}

.hoursdescbox {
  @apply w-full h-fit p-3 gap-3 flex flex-col mt-4
  bg-[#ffe5bf] border-[0.5px] border-[#f99e1d] rounded-[3px];
}
.hoursdesc {
  @apply font-[Poppins] font-[500] text-[13px] leading-[100%] text-[#3733bf];
}
.dropcheck {
  @apply divide-y-[1px] divide-[#F99E1D] bg-[#ffe5bf] border-[0.5px] border-[#f99e1d];
}
.checkbox {
  @apply flex items-center justify-between gap-4 px-3
  w-[144px] h-[32px];
}
.hoursdesc1 {
  @apply text-[#3733BF] text-[13px] leading-[100%];
}
.hoursdesctick {
  @apply w-5 h-5 bg-none  rounded-full appearance-none border-2 border-[#3733bf]
  checked:bg-[#3733bf] checked:border-2 checked:border-[#ffe5bf] checked:ring-2 checked:ring-[#3733bf] checked:ring-opacity-90 shrink-0 cursor-pointer;
}


