.container {
  @apply flex flex-row gap-4;
}

/*FOR TITLE 1 STYLE*/

.title {
  @apply font-[700] text-[20px] text-[#3733bf] font-[Poppins];
}

/*FOR SEARCH INPUT 1 STYLE*/

.search {
  @apply flex flex-row w-[300px];
}
.searchicon {
  @apply p-[11px] shrink-0 bg-[#F99E1D] rounded-l-[10px];
}
.searchinput {
  @apply placeholder:text-[#F99E1D] pl-2 flex w-full text-[#F99E1D]  text-[20px] font-medium font-[Poppins]
      bg-[#ffe5bf] rounded-r-[10px];
}

/*FOR TABLE 1 STYLE*/

.table {
  @apply flex flex-col gap-2 w-[300px];
}
.titles {
  @apply flex flex-row h-[45px] items-center bg-[#f1f2f7]
      font-[Poppins] font-medium text-[18px] text-[#3733bf]  divide-x-2 divide-[#F99E1D] py-1;
}
.titles2 {
  @apply flex flex-row h-[60px] items-center bg-[#f1f2f7]
      font-[Poppins] font-medium text-[18px] text-[#3733bf]  divide-x-2 divide-[#F99E1D] py-1;
}
.title1 {
  @apply basis-[25%] px-4 h-full items-center flex;
}
.title2 {
  @apply basis-[40%] px-4 h-full items-center flex;
}
.title3 {
  @apply basis-[35%] px-4 h-full items-center flex;
}

.datas {
  @apply flex flex-row h-[45px] items-center 
      font-[Poppins] font-medium text-[18px] text-[#3733bf]
      divide-x-2 divide-[#F99E1D]  bg-[#F1F2F7] py-1 shrink-0;
}
.datas2 {
  @apply flex flex-row h-[45px] items-center 
      font-[Poppins] font-medium text-[18px] text-[#3733bf]
      divide-x-2 divide-[#F99E1D]  bg-[#FFE5BF] py-1 shrink-0;
}
.datas3 {
  @apply flex flex-row h-[60px] items-center 
      font-[Poppins] font-medium text-[18px] text-[#3733bf]
      divide-x-2 divide-[#F99E1D]  bg-[#F1F2F7] py-1 shrink-0;
}
.datas4 {
  @apply flex flex-row h-[60px] items-center 
      font-[Poppins] font-medium text-[18px] text-[#3733bf]
      divide-x-2 divide-[#F99E1D]  bg-[#FFE5BF] py-1 shrink-0;
}

.sdatas {
  @apply flex flex-row h-[45px] items-center 
      font-[Poppins] font-medium text-[18px] text-white
      divide-x-2 divide-[#F99E1D]  bg-[#3733BF] py-1 shrink-0;
}
.sdatas3 {
  @apply flex flex-row h-[60px] items-center 
      font-[Poppins] font-medium text-[18px] text-white
      divide-x-2 divide-[#F99E1D]  bg-[#3733BF] py-1 shrink-0;
}

.data1 {
  @apply basis-[25%] px-4 h-full items-center flex;
}
.data2 {
  @apply basis-[40%] px-4 h-full items-center flex;
}
.data3 {
  @apply basis-[35%] px-4 h-full items-center flex;
}

/*FOR SEARCH INPUT 2 STYLE*/

.search1 {
  @apply flex flex-row w-[400px];
}

/*FOR TABLE 2 STYLE*/

.table1 {
  @apply flex flex-col gap-2 w-[400px];
}

.title5 {
  @apply basis-[42%] px-4 h-full items-center flex;
}
.title6 {
  @apply basis-[34%] px-4 h-full items-center flex;
}
.title7 {
  @apply basis-[24%] px-4 h-full items-center flex;
}

.data5 {
  @apply basis-[42%] px-4 h-full items-center flex flex-wrap text-start;
}
.data6 {
  @apply basis-[34%] px-4 h-full items-center flex;
}
.data7 {
  @apply basis-[24%] px-4 h-full items-center flex;
}

/*FOR SEARCH INPUT 3 STYLE*/

.search2 {
  @apply flex flex-row w-[600px];
}

/*FOR TABLE 3 STYLE*/

.table2 {
  @apply flex flex-col gap-2 w-[600px];
}
.title8 {
  @apply basis-[30%] px-4 h-full items-center flex;
}
.title9 {
  @apply basis-[46%] px-4 h-full items-center flex;
}
.title10 {
  @apply basis-[12%] px-4 h-full items-center flex;
}
.title11 {
  @apply basis-[12%] px-4 h-full items-center flex;
}

.data8 {
  @apply basis-[30%] px-4 h-full items-center flex;
}
.data9 {
  @apply basis-[46%] px-4 h-full items-center flex;
}
.data10 {
  @apply basis-[12%] px-4 h-full items-center flex;
}
.data11 {
  @apply basis-[12%] px-4 h-full items-center flex;
}

/*FOR SEARCH INPUT 4 STYLE*/

.search3 {
  @apply flex flex-row w-[450px] h-[42px];
}

/*FOR TABLE 4 STYLE*/

.table3 {
  @apply flex flex-col gap-2 w-[450px];
}
.title12 {
  @apply basis-[17%] px-4 h-full items-center flex;
}
.title13 {
  @apply basis-[50%] px-4 h-full items-center flex;
}
.title14 {
  @apply basis-[18%] px-4 h-full items-center flex;
}
.title15 {
  @apply basis-[15%] px-4 h-full items-center flex;
}

.data12 {
  @apply basis-[17%] px-4 h-full items-center flex;
}
.data13 {
  @apply basis-[50%] px-4 h-full items-center flex;
}
.data14 {
  @apply basis-[18%] px-4 h-full items-center flex;
}
.data15 {
  @apply basis-[15%] px-4 h-full items-center flex ;
}
