.container {
    @apply flex
}

.dropdown {
    @apply p-2 rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff] text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%] min-w-[221px] h-[32px] relative flex flex-row;
}

.dropdown_btn {
    @apply ms-auto px-1
}

.optionsarea {
    @apply origin-top-right absolute left-0 mt-6 w-[267px] h-[155px] rounded-[3px] shadow-lg bg-[#FFE5BF] z-50 border-t-[0.3px] border-r-[0.3px] border-l-[0.3px] border-[#F99E1D];
}

.option {
    @apply w-[267px] h-[31px] text-[#3733bf] text-start border-b-[0.3px] border-[#F99E1D] ps-2
}

.filter_input {
    @apply flex items-center ms-[1.5rem] px-2 min-w-[221px] h-[32px] rounded-[3px] border-[0.5px] border-[#b6b4ff] bg-[#eef8ff] text-[#3733bf] font-[Poppins] font-[500] text-[15px] leading-[100%];
}
.input {
    @apply bg-transparent outline-none text-[#3733bf]
}
.input::placeholder {
    @apply text-[#3733bf]
}
.search_icon {
    @apply w-[20px] h-[20px] 
}