.container {
    @apply flex flex-row justify-between  my-2 border-b-2 border-[#3733BF]
    max-sm:flex-wrap;
  }
  
  .section {
    @apply cursor-pointer font-[Poppins] font-[700] text-[13px] leading-[100%] text-[#f99e1d] 
    px-[16px] pt-[6px] pb-[10px] 
    w-full text-center;
  }
  .ssection {
    @apply cursor-pointer font-[Poppins] font-[700] text-[13px] leading-[100%] text-white 
    bg-[#3733BF] sm:rounded-t-[12px] max-sm:rounded-[5px]
    px-[16px] pt-[6px] pb-[10px] 
    w-full text-center;
  }
  