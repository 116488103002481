.container {
  @apply flex flex-col gap-3 min-w-[1800px];
}
.titles {
  @apply flex flex-row h-[45px] items-center  bg-[#ffe5bf]
  font-[Poppins] font-medium text-[18px] text-[#3733bf]  divide-x-2 divide-[#F99E1D] py-1;
}
.title1 {
  @apply basis-[8%] px-4 h-full items-center flex;
}
.title2 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.title3 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.title4 {
  @apply basis-[15%] px-4 h-full items-center flex;
}
.title5 {
  @apply basis-[16%] px-4 h-full items-center flex;
}
.title6 {
  @apply basis-[16%] px-4 h-full items-center flex;
}
.title7 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.title8 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.title9 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.title10 {
  @apply basis-[8%] px-4 h-full items-center flex truncate;
}
.title11 {
  @apply basis-[5%] px-4 h-full items-center flex;
}

.datas {
  @apply flex flex-row h-[45px] items-center 
  font-[Poppins] font-medium text-[18px] text-[#3733bf]
  divide-x-2 divide-[#F99E1D]  bg-[#F1F2F7] py-1;
}
.data1 {
  @apply basis-[8%] px-4 h-full items-center flex;
}
.data2 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.data3 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.data4 {
  @apply basis-[15%] px-4 h-full items-center flex;
}
.data5 {
  @apply basis-[16%] px-4 h-full items-center flex;
}
.data6 {
  @apply basis-[16%] px-4 h-full items-center flex;
}
.data7 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.data8 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.data9 {
  @apply basis-[7%] px-4 h-full items-center flex;
}
.data10 {
  
  @apply basis-[8%] px-4 h-full items-center flex truncate;
}
.data11 {
  @apply basis-[5%]   px-4 h-full items-center flex;
}

.tab {
  @apply flex flex-row h-[30px] place-self-end;
}
.tabicon {
  @apply w-[30px] items-center justify-center flex rounded-l-[6px] bg-[#3733bf];
}
.tabtext {
  @apply py-2 px-4  flex items-center cursor-default  bg-[#afddff] rounded-r-[6px] text-[#3733bf]  
  font-[Poppins] font-[500] text-[15px] leading-[100%];
}
