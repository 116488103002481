.container {
  @apply flex flex-col gap-3 min-w-[1300px];
}
.titles {
  background: #ffe5bf;
  @apply flex flex-row h-[45px] items-center 
  font-[Poppins] font-medium text-[18px] text-[#3733bf]  divide-x-2 divide-[#F99E1D] py-1;
}
.title1 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.title2 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.title3 {
  @apply basis-[24%] px-4 h-full items-center flex;
}
.title4 {
  @apply basis-[24%] px-4 h-full items-center flex;
}
.title5 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.title6 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.title7 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.title8 {
  @apply basis-[7%] px-4 h-full items-center flex;
}

.datas {
  @apply flex flex-row h-[45px] items-center 
  font-[Poppins] font-medium text-[18px] text-[#3733bf]
  divide-x-2 divide-[#F99E1D]  bg-[#F1F2F7] py-1;
}
.data1 {
  @apply basis-[5%] px-4 h-full items-center flex;
}
.data2 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.data3 {
  @apply basis-[24%] px-4 h-full items-center flex;
}
.data4 {
  @apply basis-[24%] px-4 h-full items-center flex;
}
.data5 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.data6 {
  @apply basis-[10%]  px-4 h-full items-center flex;
}
.data7 {
  @apply basis-[10%] px-4 h-full items-center flex;
}
.data8 {
  @apply basis-[7%]  px-4 h-full items-center flex;
}

